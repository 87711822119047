/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import binocularsIcon from "../../static/img/binoculars_icon.svg";
import educationIcon from "../../static/img/education_icon.svg";
import laptopIcon from "../../static/img/laptop_icon.svg";
import optimizationIcon from "../../static/img/optimization_icon.svg";
import predictiveChartIcon from "../../static/img/predictive-chart_icon.svg";
import targetIcon from "../../static/img/target_icon.svg";
import uiScreenshot from "../../static/img/UI-screenshot2.png";
import Content, { HTMLContent } from "../components/Content";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  return (
    <>
      <SEO title={title} />
      <div className="pad hero two" id="about">
        <div class = "pad overlay two">
        <div className="copy about">
          Compunetics is a powerful software package used for the kinetic
          fitting of bio/chemical processes, with an emphasis on accessibility
          for the bench scientist. With an intuitive and dynamic interface, you
          can identify kinetic parameters and obtain full process understanding,
          without the substantial cost.
          <br />
          <br />
          Kinetic analysis is still seen as a specialised and impenetrable
          field, with kinetic studies scarcely reported in the general chemical
          literature. This can be mainly attributed to three obstacles for the
          average researcher: software costs, the ability to code and not
          knowing where to begin. Compunetics dismantles these obstacles by
          providing an easy-to-use and powerful kinetic analysis software for process optimisation.
          No coding knowledge is required, no kinetic expertise is required.
        </div>
        </div>
      </div>

      <div className="pad" id="usp">
        <h1 className="h4 usp">
          With Compunetics, our easy-to-use interface and step-by-step user
          guide will allow users to:
        </h1>

        <div className="flex w-container">
          <div className="usp">
            <div className="icon">
              <img alt="" height="100" src={binocularsIcon} />
            </div>

            <p className="copy usp2">Identify reaction pathways</p>
          </div>

          <div className="usp">
            <div className="icon">
              <img alt="" height="100" src={targetIcon} />
            </div>

            <p className="copy usp2">Pinpoint kinetic parameters</p>
          </div>

          <div className="usp">
            <div className="icon">
              <img alt="" height="100" src={educationIcon} />
            </div>

            <p className="copy usp2">
              Teach and learn physical-organic chemistry
            </p>
          </div>
        </div>

        <div className="flex bottom w-container">
          <div className="usp">
            <div className="icon">
              <img alt="" height="100" src={predictiveChartIcon} />
            </div>

            <p className="copy usp2">
              Predict reactivity using a physical model
            </p>
          </div>

          <div className="usp">
            <div className="icon">
              <img alt="" height="100" src={optimizationIcon} />
            </div>

            <p className="copy usp2">Optimise reaction outputs</p>
          </div>

          <div className="usp">
            <div className="icon">
              <img alt="" height="100" src={laptopIcon} />
            </div>

            <p className="copy usp2">Quantify catalytic activity</p>
          </div>
        </div>
      </div>

      <div className="pad features" id="features">
        <h1 className="h4 usp">Compunetics Features:</h1>

        <div className="flex flexlist w-container">
          <div className="list-contain">
            <ul className="list">
              <li className="list-item">
                <div className="copy list-copy">
                  <strong className="bold">Model Editor:</strong> Dynamic
                  reaction model modification.
                </div>
              </li>
              <li className="list-item">
                <div className="copy list-copy">
                  <strong className="bold">Statistical Insight:</strong>{" "}
                  Associated errors for the kinetic fitting of each individual
                  species.
                </div>
              </li>
              <li className="list-item">
                <div className="copy list-copy">
                  <strong className="bold">Versatile Plotting:</strong> Rapidly
                  alter how to convey data and kinetic information.
                </div>
              </li>
              <li className="list-item">
                <div className="copy list-copy">
                  <strong className="bold">Unique Visualisation:</strong> Plot
                  areas of potential reaction activity.
                </div>
              </li>
              <li className="list-item">
                <div className="copy list-copy">
                  <strong className="bold">Simulation:</strong> Extrapolate and
                  alter kinetic constants and starting conditions to predict how
                  a reaction would proceed.
                </div>
              </li>
              <li className="list-item">
                <div className="copy list-copy">
                  <strong className="bold">Process Optimisation:</strong> Utilise 
                  machine learning to optimise your bio/chemical process.
                </div>
              </li>
              <li className="list-item">
                <div className="copy list-copy">
                  <strong className="bold">Data Exporting:</strong> Easily
                  extract data from Compunetics for further analysis and
                  plotting.
                </div>
              </li>
            </ul>
          </div>

          <div className="ui" sx={{ flex: "0 0.5 auto" }}>
            <div className="icon"></div>

            <div className="icon">
              <img alt="Compunetics ui" className="image" src={uiScreenshot} />
            </div>
          </div>
        </div>

        <div className="copy" id="buy">
          Compunetics is available for download{" "}
          <a
            className="textlink"
            href="https://licensing.leeds.ac.uk/product/compunetics-software"
          >
            here
          </a>
          , please note you must also have specifically MATLAB Runtime 9.7&nbsp;installed -
          this is available to download for free{" "}
          <a
            className="textlink"
            href="https://uk.mathworks.com/products/compiler/matlab-runtime.html"
            target="_blank"
          >
            here
          </a>
          . <strong>Affordable</strong> educational/academic/industrial licenses are available{" "}
          . You will be redirected to a University of
          Leeds page to complete your download. Windows only.
        </div>
      </div>
    </>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
